<template>
  <div v-if="invoice && toggle">
    <div v-if="invoice.accountant_paid === 0">

        <div v-if="invoice.inv_passed === 9">
        <button class="btn-sm btn-danger" style="margin-bottom:5px; width:100%;"><i class="fa fa-times"></i> Rejected By WSS (Submit Again)</button>
        </div>
        
        <div v-if="invoice.inv_passed === 17">
        <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Accontant</button>
        </div>

        <div v-if="invoice.inv_passed === 8 && invoice.acc_project != 1">
        <button class="btn-sm btn-warning btn-pm" style="margin-bottom:5px; width:100%;"><i class="fa fa-question"></i> Pending By Project Manager</button>
        </div>

        <div v-if="invoice.inv_passed === 8 && invoice.acc_project == 1">
          <button class="btn-sm btn-warning btn-pm" style="border-color: #24cabf !important; background: #24cabf !important; margin-bottom:5px; width:100%;"><i class="fa fa-question"></i> Pending For First Approval</button>
        </div>

        <div v-if="invoice.inv_passed === 7">
        <button class="btn-sm btn-success" style="margin-bottom:5px; width:100%;"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 6">
        <button class="btn-sm btn-warning btn-management" style="margin-bottom:5px; width:100%;"><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 5">
        <button class="btn-sm btn-danger" style="margin-bottom:5px; width:100%;"><i class="fa fa-times"></i> Rejected By Project Manager (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 0">
        <button class="btn-sm btn-warning" style="margin-bottom:5px; width:100%;"><i class="fa fa-question"></i> Pending By WSS</button>
        </div>

        <div v-if="invoice.inv_passed === 11">
        <button class="btn-sm btn-success" style="margin-bottom:5px; width:100%;"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 13">
        <button class="btn-sm btn-warning btn-management" style="margin-bottom:5px; width:100%;"><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 14">
        <button class="btn-sm btn-danger" style="margin-bottom:5px; width:100%;"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 21">
        <button class="btn-sm btn-success" style="margin-bottom:5px; width:100%;"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 23">
        <button class="btn-sm btn-warning btn-management" style="margin-bottom:5px; width:100%;"><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 24">
        <button class="btn-sm btn-danger" style="margin-bottom:5px; width:100%;"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 31">
        <button class="btn-sm btn-success" style="margin-bottom:5px; width:100%;"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 33">
        <button class="btn-sm btn-warning btn-management" style="margin-bottom:5px; width:100%;"><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 34">
        <button class="btn-sm btn-danger" style="margin-bottom:5px; width:100%;"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 41">
        <button class="btn-sm btn-success" style="margin-bottom:5px; width:100%;"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 43">
        <button class="btn-sm btn-warning btn-management" style="margin-bottom:5px; width:100%;"><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 44">
        <button class="btn-sm btn-danger" style="margin-bottom:5px; width:100%;"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 51">
        <button class="btn-sm btn-success" style="margin-bottom:5px; width:100%;"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 54">
        <button class="btn-sm btn-danger" style="margin-bottom:5px; width:100%;"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>
    </div>
    <div v-if="invoice.accountant_paid === 1">
        <button class="btn-sm btn-success" style="margin-bottom:5px; width:100%;"><i class="fa fa-check"></i> Paid</button>
    </div>
</div>

 <div v-else>
    <div v-if="invoice.accountant_paid === 0">

        <div v-if="invoice.inv_passed === 9">
        <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By WSS (Submit Again)</button>
        </div>
        
        <div v-if="invoice.inv_passed === 17">
        <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Accontant</button>
        </div>

        <div v-if="invoice.inv_passed === 8 && invoice.acc_project != 1">
        <button class="btn-sm btn-warning btn-pm"><i class="fa fa-question"></i> Pending By Project Manager</button>
        </div>

        <div v-if="invoice.inv_passed === 8 && invoice.acc_project == 1">
          <button class="btn-sm btn-warning btn-pm" style="border-color: #24cabf !important; background: #24cabf !important;"><i class="fa fa-question"></i> Pending For First Approval</button>
        </div>

        <div v-if="invoice.inv_passed === 7">
        <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 6">
        <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 5">
        <button class="btn-sm btn-danger" ><i class="fa fa-times"></i> Rejected By Project Manager (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 0">
        <button class="btn-sm btn-warning" ><i class="fa fa-question"></i> Pending By WSS</button>
        </div>

        <div v-if="invoice.inv_passed === 11">
        <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 13">
        <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 14">
        <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 21">
        <button class="btn-sm btn-success" ><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 23">
        <button class="btn-sm btn-warning btn-management" ><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 24">
        <button class="btn-sm btn-danger" ><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 31">
        <button class="btn-sm btn-success" ><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 33">
        <button class="btn-sm btn-warning btn-management" ><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 34">
        <button class="btn-sm btn-danger"><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 41">
        <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 43">
        <button class="btn-sm btn-warning btn-management"><i class="fa fa-question"></i> Pending By Higher Management</button>
        </div>

        <div v-if="invoice.inv_passed === 44">
        <button class="btn-sm btn-danger" ><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>

        <div v-if="invoice.inv_passed === 51">
        <button class="btn-sm btn-success"><i class="fa fa-check"></i> Ready For Payment</button>
        </div>

        <div v-if="invoice.inv_passed === 54">
        <button class="btn-sm btn-danger" ><i class="fa fa-times"></i> Rejected By Management (Pending Vendor)</button>
        </div>
    </div>
    <div v-if="invoice.accountant_paid === 1">
        <button class="btn-sm btn-success"><i class="fa fa-check"></i> Paid</button>
    </div>
</div>

</template>

<script>

export default {
    props: ['invoice'],
    data : function () {
        return {
            //invoice: invoice
        }
    },
    methods : {
        mounted() {

        }
    }
}
</script>
